<template>
  <v-main v-if="renderNow" style="height: 100%; padding: 0px">
    <v-dialog v-model="showContactForm" max-width="450px">
      <v-card rounded style="border-radius: 10px !important">
        <v-card-title style="white-space: nowrap" class="justify-content-center"
          >Inquire About This Course</v-card-title
        >
        <submission-alert-message
          :message="badSubmissionMessage[$language]"
          v-model="showGeneralMessage"
          ref="alertMessage"
        ></submission-alert-message>
        <v-form
          ref="form"
          v-model="formValidated"
          style="z-index: 6; max-width: 100%; padding: 5%"
        >
          <v-container>
            <v-text-field
              v-model="name"
              dense
              flat
              style="max-height: 25px"
              outlined
              label="Your Name"
              :rules="nameRules"
              validate-on-blur
            >
            </v-text-field>
            <v-text-field
              v-model="phone"
              :prefix="showPhonePrefix ? '+880' : ''"
              dense
              class="mt-8"
              style="max-height: 25px"
              outlined
              label="Phone Number"
              :rules="[required, phoneValidation]"
              validate-on-blur
              @focus="showPhonePrefix = true"
              @blur="hidePhonePrefix()"
            >
            </v-text-field>
            <v-text-field
              v-model="email"
              class="mt-8"
              dense
              style="max-height: 25px"
              outlined
              label="Email Address"
              :rules="[emailValidation]"
              validate-on-blur
            >
            </v-text-field>
            <v-textarea
              v-model="message"
              class="mt-8"
              auto-grow
              outlined
              label="Type your message"
            >
            </v-textarea>
          </v-container>
          <v-row justify="center">
            <v-btn
              elevation="0"
              class="submit-btn white--text"
              width="50%"
              color="#0099DC"
              height="42"
              @click="submitContactForm()"
              >Send</v-btn
            >
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card color="#EBF2FF" flat class="basic-details-style">
      <v-row>
        <v-col
          :cols="screenSize < 900 ? '12' : '8'"
          class="d-flex flex-column justify-center basic-details-first-row"
        >
          <span class="details-header">
            {{ course.course_title }}
          </span>
          <span class="details-course-code" style="font-weight: 400">
            <span class="details-header">(</span>
            Course-Code: {{ course.course_key }}
            <span class="details-header">)</span>
          </span>
        </v-col>
      </v-row>
      <v-row class="d-flex basic-details-later-rows">
        <v-col :cols="screenSize < 600 ? '12' : '8'" class="details-sub-header">
          <v-row>
            <span class="details-course-code" style="font-weight: 600"
              >Course Instructor:</span
            >
            <span
              class="details-course-code"
              style="font-weight: 500; padding-left: 1%"
              >{{ Object.values(instructors)[0] }}</span
            >
            <span
              v-if="numInstrcutors > 1"
              class="details-course-code"
              style="font-weight: 500; padding-left: 1%"
              >+{{ numInstrcutors }} more instructors</span
            >
          </v-row>
          <v-row :class="screenSize < 600 ? 'mt-1' : 'mt-3'">
            <span class="details-course-code" style="font-weight: 600"
              >Offered By:</span
            ><span
              class="details-course-code"
              style="font-weight: 500; padding-left: 1%"
            >
              {{ course.organization_name }}</span
            >
          </v-row>
          <v-row class="d-flex course-button-section">
            <v-btn
              id="public-course-details-enroll-now-button"
              depressed
              color="#0099DC"
              class="white--text course-button-class"
              :disabled="!(subscribeButtonText.toLowerCase() === 'add to cart')"
              @click="enrollNowClicked()"
            >
              <span>Enroll Now</span>
            </v-btn>
            <v-btn
              id="public-course-details-contact-us-button"
              depressed
              class="white--text course-button-class contact-btn"
              color="#0099DC"
              @click="showContactForm = !showContactForm"
            >
              <span>Contact</span>
            </v-btn>
          </v-row>
          <v-row v-if="course.facebook_group" class="d-flex align-center">
            <a
              id="public-course-details-facebook-button"
              :href="course.facebook_group"
              :class="[
                screenSize >= 900 ? 'mt-6' : 'mt-3',
                'details-course-code',
              ]"
              target="_blank"
              ><span style="color: #0099dc"
                >Join our Facebook Group to stay connected</span
              ></a
            >
          </v-row>
        </v-col>
        <v-col :cols="screenSize < 600 ? '12' : '4'" class="d-flex justify-end">
          <v-card
            v-if="courseMaterialExists"
            flat
            class="course-inclusion-card-style"
          >
            <v-row class="pa-0">
              <v-col :cols="screenSize < 600 ? '4' : '12'" class="pa-0">
                <v-card flat class="inner-card">
                  <v-img
                    v-if="'image_url' in course"
                    alt="course image"
                    :src="courseImage"
                    class="course-image"
                  ></v-img>
                  <div
                    v-else
                    style="
                      background: #666666;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                    "
                  >
                    <v-avatar :size="avatarSize" color="white">
                      <span class="course-no-image">{{
                        getCourseImageFromTitle(course.course_title)
                      }}</span>
                    </v-avatar>
                  </div>
                  <!--                The following two lines can be uncommented when and if the favorite feature is made active in future   -->
                  <!-- <img v-if="!heartClicked" style="margin-left: 80%; margin-top: 10%; position:absolute;top: 0; left: 0;" @click="heartClicked = !heartClicked" src="../modules/home/components/search/icons/love.svg" alt="Love"> -->
                  <!-- <img v-else style="margin-left: 80%; margin-top: 10%; position:absolute;top: 0; left: 0;" @click="heartClicked = !heartClicked" src="../modules/home/components/search/icons/love_filled.svg" alt="Love filled"> -->
                </v-card>
              </v-col>
              <v-col :cols="screenSize < 600 ? '8' : '12'" class="pa-0">
                <div class="course-text-header">This Course Includes</div>
                <v-row :class="screenSize < 600 ? '' : 'pt-5 pa-0'">
                  <v-col
                    v-for="(item, index) in svglocs"
                    :key="index"
                    :cols="screenSize < 600 ? '6' : '12'"
                    :class="[
                      'd-flex justify start align-center course-text-holder',
                      {
                        'pa-0 ma-0':
                          Number(course.course_materials[item.iname]) == 0,
                      },
                    ]"
                  >
                    <div v-if="Number(course.course_materials[item.iname]) > 0">
                      <img
                        class="course-icons"
                        :src="item.svg"
                        alt="Item"
                      /><span class="course-text"
                        >{{ course.course_materials[item.iname] }}
                        {{ item.name }}</span
                      ><span
                        v-if="course.course_materials[item.iname] > 0"
                        class="course-text"
                        style="padding: 0px"
                        >{{ item.mult }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <div style="margin: 2% 5%">
      <v-card flat style="padding-top: 1%">
        <v-tabs id="tabs" v-model="tab" :show-arrows="false">
          <v-tab id="public-course-details-about-tab" class="tab-class"
            >About</v-tab
          >
          <v-tab id="public-course-details-outline-tab" class="tab-class"
            >Outline</v-tab
          >
          <v-tab id="public-course-details-instructors-tab" class="tab-class"
            >Instructor(s)</v-tab
          >
          <v-tab id="public-course-details-enrollmet-tab" class="tab-class"
            >Enrollment Options</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab" style="margin-bottom: 6%">
          <v-tab-item class="tab-item-style">
            <div class="mt-6" style="font-size: 20px; font-weight: bold">
              About This Course
            </div>
            <div>
              <v-row v-if="course.about_video" no-gutter>
                <v-col
                  :cols="screenSize < 600 ? '12' : '8'"
                  class="px-0 pt-5 pb-10 d-flex justify-center"
                >
                  <iframe
                    class="m-iframe"
                    :src="processAboutVid"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
                    allowfullscreen
                  >
                  </iframe>
                </v-col>
              </v-row>
              <div
                v-if="!course.about_video"
                class="with-inner-html"
                v-html="course.course_description"
              ></div>
              <div v-else class="faker"></div>
            </div>
          </v-tab-item>
          <v-tab-item class="tab-item-style">
            <div class="mt-6" style="font-size: 20px; font-weight: bold">
              Outline for this course
            </div>
            <div
              class="mt-6 with-inner-html"
              style="font-size: 14px"
              v-html="course.syllabus"
            ></div>
          </v-tab-item>
          <v-tab-item class="tab-item-style">
            <div class="mt-6" style="font-size: 20px; font-weight: bold">
              Instructors
            </div>
            <v-layout wrap style="width: 86%; margin-top: 2%">
              <v-flex
                v-for="(instructor, i) in instructors"
                :key="i"
                class="mb-8"
                sm6
                md6
                lg6
              >
                <v-row style="width: 100vw" class="pl-4">
                  <v-avatar
                    color="#445599"
                    :size="$vuetify.breakpoint.width < 1360 ? 70 : 100"
                  >
                    <span class="white--text headline">{{
                      getImageFromTitle(instructor)
                    }}</span>
                  </v-avatar>
                  <div class="ml-6">
                    <v-row style="font-size: 20px">{{ instructor }}</v-row>
                    <v-row style="font-size: 14px; color: #707070">{{
                      course.organization_name
                    }}</v-row>
                    <v-row
                      style="font-size: 16px; color: #0089cb; cursor: pointer"
                      @click="scrollTo('more-courses')"
                      >View more courses by this organization</v-row
                    >
                  </div>
                </v-row>
              </v-flex>
              <v-flex v-if="instructors.length < 1"
                >The are no instructors in this course.</v-flex
              >
            </v-layout>
            <div class="mt-6" style="font-size: 20px; font-weight: bold">
              Offered By :
              <v-row class="pl-3 mt-4">
                <v-avatar color="#445599">
                  <span class="white--text">{{
                    getImageFromTitle(course.organization_name)
                  }}</span>
                </v-avatar>
                <div class="mt-2 ml-2">{{ course.organization_name }}</div>
              </v-row>
            </div>
          </v-tab-item>

          <v-tab-item class="tab-item-style">
            <div class="mt-6 pl-0 pt-6">
              <div style="font-size: 20px; font-weight: bold">Batches</div>
              <v-flex
                v-if="renderBatchesNow === false"
                class="d-flex justify-content-center"
                style="padding-top: 6%"
              >
                <v-progress-circular
                  indeterminate
                  color="#0099dc"
                ></v-progress-circular>
              </v-flex>
              <div v-if="renderBatchesNow === true">
                <batch-card
                  v-for="batch in batchesForCourse"
                  :key="batch.batch_id"
                  :batch="batch"
                  :course="course"
                  :subscribe-button-text.sync="subscribeButtonText"
                  class="mt-6"
                  @added="getCartItems()"
                ></batch-card>
              </div>
              <v-flex v-else-if="batchesForCourse.length < 1" class="mt-6"
                >The are no batches in this course.</v-flex
              >
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <div v-if="moreCoursesByOrg.length > 1" style="margin-bottom: 2%">
        <v-row
          style="display: flex; justify-content: space-between; width: 90%"
        >
          <div id="more-courses" class="more-header">
            More Courses by this Organization
          </div>
          <div
            v-if="moreCoursesByOrg.length > 4 && exploringMore === false"
            style="
              font-size: 14px;
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
            "
            @click="exploringMore = true"
          >
            Explore More
          </div>
        </v-row>
        <div
          v-if="exploringMore === true"
          style="
            height: 320px;
            width: 90%;
            margin-top: 1.4%;
            overflow-y: hidden;
            white-space: nowrap;
          "
        >
          <course-search-card
            v-for="(c, i) in moreCoursesByOrg"
            :key="i"
            class="mr-6"
            style="display: inline-block"
            :course="c"
            @clicked="
              fetchCourseDetails();
              fetchBatchesForCourse();
            "
          />
        </div>
        <div
          v-else
          style="
            height: 320px;
            max-width: 90%;
            margin-top: 1.4%;
            overflow-y: scroll;
            white-space: nowrap;
          "
        >
          <course-search-card
            v-for="(c, i) in moreCoursesByOrg.slice(0, 6)"
            :key="i"
            class="mr-6"
            style="display: inline-block"
            :course="c"
            @clicked="
              fetchCourseDetails();
              fetchBatchesForCourse();
            "
          />
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { Logger } from "/global/utils/helpers";
import axios from "axios";
import BatchCard from "#ef/course/components/search/BatchCard";
import CourseSearchCard from "#ef/course/components/search/CourseSearchCard";
import { UserApi } from "~/modules/user/UserApi";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage.vue";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "NewCourseDetails",
  components: {
    BatchCard,
    CourseSearchCard,
    SubmissionAlertMessage,
  },
  props: {
    partner_code: {
      type: String,
      required: true,
    },
    course_id: {
      type: String,
      required: true,
    },
    is_enroll_trigger: {
      type: Boolean,
    },
  },
  mixins: [FieldValidations],
  data() {
    return {
      cartItemsDict: {},
      cartItems: [],
      studentCourses: [],
      exploringMore: false,
      showContactForm: false,
      showPhonePrefix: false,
      formValidated: false,
      showGeneralMessage: false,
      isSent: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      nameRules: [this.required],
      emailRules: [this.emailValidation],
      phoneRules: [this.required, this.phoneValidation],
      messageRules: [(v) => !!v || "Message is required"],
      instructors: {},
      batchesForCourse: [],
      moreCoursesByOrg: [],
      heartClicked: false,
      renderNow: false,
      renderBatchesNow: false,
      tab: 0,
      course: {},
      svglocs: [
        {
          name: "Video Lecture",
          iname: "video_lectures",
          svg: require("../../../assets/video_lectures.svg"),
          mult: "s",
        },
        {
          name: "Lecture Note",
          iname: "lecture_notes",
          svg: require("../../../assets/writing.svg"),
          mult: "s",
        },
        {
          name: "Live Class",
          iname: "live_classes",
          svg: require("../../../assets/live_classes.svg"),
          mult: "es",
        },
        {
          name: "Quiz",
          iname: "quizzes",
          svg: require("../../../assets/quiz.svg"),
          mult: "zes",
        },
        {
          name: "Assignment",
          iname: "assignments",
          svg: require("../../../assets/assignments.svg"),
          mult: "s",
        },
        {
          name: "Exam",
          iname: "exams",
          svg: require("../../../assets/exams.svg"),
          mult: "s",
        },
        {
          name: "Project",
          iname: "projects",
          svg: require("../../../assets/projects.svg"),
          mult: "s",
        },
      ],
    };
  },
  watch: {
    formValidated: {
      handler(value) {
        if (value) {
          this.showGeneralMessage = false;
        }
      },
    },
  },
  computed: {
    badSubmissionMessage() {
      return {
        true: "সকল প্রয়োজনীয় তথ্য প্রদান করুন ",
        false:
          "Must validate all mandatory fields",
      };
    },
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    courseImage() {
      return "image_url" in this.course ? this.course.image_url : "";
    },
    courseMaterialExists() {
      return "course_materials" in this.course;
    },
    doesSessionExist() {
      return this.$store.state.user.authToken !== undefined;
    },
    sizes() {
      return {
        cardHeight: this.$vuetify.breakpoint.lgAndDown ? "165px" : "240px",
        cardWidth: this.$vuetify.breakpoint.lgAndDown ? "338px" : "500px",
        bodyFontSize: ".875rem",
        imageWidth: this.$vuetify.breakpoint.lgAndDown ? "107px" : "140px",
        imageHeight: this.$vuetify.breakpoint.lgAndDown ? "165px" : "240px",
        titleFontSize: "1.5rem",
        statFont: this.$vuetify.breakpoint.lgAndDown ? "14px" : "16px",
        lineClamp: 2,
        stat: {
          height: this.$vuetify.breakpoint.lgAndDown ? "43px" : "69px",
        },
      };
    },
    numInstrcutors() {
      return Object.values(this.instructors).length;
    },
    avatarSize() {
      let s = this.screenSize;
      return s < 600 ? "80" : s < 900 ? "120" : s < 1601 ? "150" : "180";
    },
    processAboutVid() {
      let url = this.course.about_video;
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      let code = match && match[2].length === 11 ? match[2] : null;
      if (!code) return this.course.about_video;
      else {
        return `https://www.youtube.com/embed/${code}`;
      }
    },
    subscribeButtonText() {
      if (
        this.studentCourses === undefined ||
        this.studentCourses.length === 0
      ) {
        return "Add To Cart";
      } else {
        if (
          "is_subscribed" in this.studentCourses[0] &&
          this.studentCourses[0]["is_subscribed"] === true
        ) {
          return "Enrolled";
        }
        if (this.course_id in this.cartItemsDict) {
          return "Added To Cart";
        }
        return "Add To Cart";
      }
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    await this.fetchCourseDetails();
    await this.fetchMoreCoursesByOrg();
    await this.fetchBatchesForCourse();
    if (this.$store.state.user.authToken !== undefined) {
      await this.getStudentCourses();
      await this.getCartItems();
    }
    if (this.is_enroll_trigger) {
      this.enrollNowClicked();
    }
  },
  methods: {
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map((w) => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    getImageFromTitle(name) {
      let words = name.split(" ");
      return words
        .map((w) => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    enrollNowClicked() {
      this.tab = 3;
      this.scrollTo("tabs");
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    hidePhonePrefix() {
      if (this.phone === "") this.showPhonePrefix = false;
    },
    async getStudentCourses() {
      let API_URL = process.env.VUE_APP_BACKEND_EDU_STUDENTS_URL;
      let data = {
        course_id: this.course_id,
      };
      const token = process.env.VUE_APP_AUTH_TOKEN || UserApi.loadAuthToken();
      let requestId = "students_get_courses-" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "students_get_courses",
          "Request-Id": requestId,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          this.studentCourses = response.data.content.courses;
        })
        .catch((error) => {
          Logger.error(error);
        });
    },
    async getCartItems() {
      let API_URL = process.env.VUE_APP_BACKEND_EVENTS_URL;
      let data = {};
      const token = process.env.VUE_APP_AUTH_TOKEN || UserApi.loadAuthToken();
      let requestId = "payment_get_cart_items-" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "payment_get_cart_items",
          "Request-Id": requestId,
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          this.cartItems = response.data.content.items;
          this.cartItems.map(
            // item => (this.cartItemsDict[item.course_id] = item.item_title),
            (item) =>
              this.$set(this.cartItemsDict, item.course_id, item.item_title)
          );
          this.renderBatchesNow = true;
        })
        .catch((error) => {
          Logger.error(error);
        });
    },
    closeMessage() {
      this.showGeneralMessage = false;
    },
    async submitContactForm() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      if (!this.formValidated) {
        if (this.showGeneralMessage) {
          this.$refs.alertMessage.flash();
        }
        this.showGeneralMessage = true;
        return;
      }
      if (this.isSent) {
        return;
      }
      let API_URL = process.env.VUE_APP_BACKEND_PUBLIC_URL;
      let UserData = {
        sender_name: this.name,
        sender_email: this.email,
        sender_phone: this.phone,
        message: this.message,
        request_type: "send_contact_info",
      };
      if (this.email.trim().length===0){
        delete UserData["sender_email"];
      }
      this.isSent = true;
      await axios(API_URL, {
        method: "POST",
        data: UserData,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.response = response.data;
          this.name = "";
          this.email = "";
          this.phone = "";
          this.message = "";
          this.formValidated = false;
          this.isSent = false;
          this.$root.$emit("alert", ["Success", "Thank you, We have received your message"])
        })
        .catch((error) => {
          Logger.error(error);
          this.isSent = false;
        });
    },
    async fetchCourseDetails() {
      let API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let Data = {
        partner_code: this.partner_code,
        course_id: this.course_id,
      };
      let requestId = "public_get_course_details-" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: Data,
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_get_course_details",
          "Request-Id": requestId,
        },
      })
        .then((response) => {
          this.course = response.data.content.course_details;
          this.renderNow = true;
        })
        .catch((error) => {
          Logger.error(error);
        });
    },
    async fetchMoreCoursesByOrg() {
      let API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let Data = {
        partner_code: this.partner_code,
        course_id: this.course_id,
      };
      let requestId =
        "public_get_more_courses_for_org" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: Data,
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_get_more_courses_for_org",
          "Request-Id": requestId,
        },
      })
        .then((response) => {
          this.moreCoursesByOrg = response.data.content.courses;
        })
        .catch((error) => {
          Logger.error(error);
        });
    },
    async fetchBatchesForCourse() {
      let API_URL = process.env.VUE_APP_BACKEND_PUBLIC_COURSES_URL;
      let Data = {
        partner_code: this.partner_code,
        course_id: this.course_id,
      };
      let requestId = "public_get_batches" + Math.random(0, 99999999);
      await axios(API_URL, {
        method: "POST",
        data: Data,
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_get_batches",
          "Request-Id": requestId,
        },
      })
        .then((response) => {
          this.batchesForCourse = response.data.content.batches;
          for (let i = 0; i < this.batchesForCourse.length; i++) {
            let batch = this.batchesForCourse[i];
            if ("teacher_id" in batch) {
              this.instructors[batch.teacher_id] = batch.teacher_name;
            }
          }
          this.renderBatchesNow = true;
        })
        .catch((error) => {
          Logger.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog__container {
  display: unset !important;
}

ul {
  color: #404040;
  font-size: 14px !important;
  list-style: inside url(../../../assets/syllabus_circle_icon.svg);
  padding-left: 40px;
  vertical-align: center;
}

li {
  padding-top: 20px;
}

.p-class {
  font-size: 14px;
  color: #404040;
}

.with-inner-html::v-deep {
  overflow-x: auto;
  * {
    color: black;
  }
  table {
    border-collapse: collapse;
    th,
    td {
      border: 2px solid black;
      padding: 5px 10px;
      @media screen and (max-width: 599px) {
        padding: 2px 5px;
      }
    }
  }
}

.course-button-section {
  @media screen and (min-width: 1601px) {
    margin-top: 24px;
    padding: 0px 16px;
    padding-top: 16px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    margin-top: 24px;
    padding: 0px 16px;
    padding-top: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    margin-top: 20px;
    padding: 0px 10px;
    padding-top: 10px;
  }

  @media screen and (max-width: 599px) {
    padding: 15px;
  }
}

.course-button-class {
  font-weight: 600;
  @media screen and (min-width: 1601px) {
    height: 70px !important;
    width: 200px !important;
    font-size: 22px;
    margin-right: 40px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    height: 45px !important;
    width: 140px !important;
    font-size: 18px;
    margin-right: 25px;
    margin-bottom: 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 35px !important;
    font-size: 16px;
    margin-right: 12px;
  }

  @media screen and (max-width: 599px) {
    font-weight: 500;
    height: 30px !important;
    font-size: 12px;
    margin-right: 12px;
  }
}

.tab-class {
  color: #0099dc;
  text-transform: none;
  justify-content: center;
  min-width: 30px;
  width: auto;
  @media screen and (max-width: 599px) {
    font-size: 12px;
    padding: 0px 5px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 16px;
  }
}

.basic-details-style {
  margin: 2% 5%;
}

.basic-details-first-row {
  position: relative;
  @media screen and (min-width: 1601px) {
    padding: 0px 50px;
    top: 30px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    padding: 0px 30px;
    top: 25px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    padding: 0px 25px;
    top: 25px;
  }

  @media screen and (max-width: 599px) {
    padding: 0px 15px;
    top: 10px;
  }
}

.basic-details-later-rows {
  @media screen and (min-width: 1601px) {
    height: 375px;
    padding: 0px 50px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    height: 300px;
    padding: 0px 30px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 240px;
    padding: 50px 10px 0px 25px;
  }

  @media screen and (max-width: 599px) {
  }
}

.details-sub-header {
  @media screen and (min-width: 1601px) {
    padding-top: 80px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    padding-top: 70px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
  }

  @media screen and (max-width: 599px) {
    padding: 15px 15px 10px 15px;
  }
}

.details-header {
  font-weight: 600;
  display: inline-block;
  @media screen and (min-width: 1601px) {
    font-size: 32px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 28px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 24px;
  }

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}

.details-course-code {
  @media screen and (min-width: 1601px) {
    font-size: 22px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.available-batch-captions {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
  @media screen and (min-width: 1601px) {
    margin-left: 10px;
    margin-top: 20px;
    font-size: 12px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 11px;
    margin-left: 10px;
    margin-top: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 11px;
    margin-left: 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.course-inclusion-card-style {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #0089cb;
  z-index: 1;
  height: auto;
  @media screen and (min-width: 1601px) {
    width: 400px !important;
    padding-bottom: 30px;
    .inner-card {
      height: 250px;
      color: #dcdcdc;
      border-radius: 20px 20px 0px 0px;
    }
  }

  @media screen and (max-width: 599px) {
    .inner-card {
      height: 140px;
      width: 110px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    width: 320px !important;
    padding-bottom: 20px;
    .inner-card {
      height: 200px;
      color: #dcdcdc;
      border-radius: 20px 20px 0px 0px;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    width: 320px !important;
    padding-bottom: 20px;
    .inner-card {
      height: 160px;
      color: #dcdcdc;
      border-radius: 20px 20px 0px 0px;
    }
  }

  @media screen and (max-width: 599px) {
    border-radius: 0px;
  }
}

.course-image {
  border-radius: 20px 20px 0px 0px;
  height: 100%;
  width: 100%;
}

.course-no-image {
  color: #666666;
  font-weight: 500;
  @media screen and (min-width: 1601px) {
    font-size: 65px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 50px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 40px;
  }

  @media screen and (max-width: 599px) {
    font-size: 32px;
  }
}

.course-text-header {
  font-weight: 600;
  @media screen and (min-width: 1601px) {
    font-size: 20px;
    padding: 0px 25px;
    padding-bottom: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 16px;
    padding: 0px 25px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 16px;
    padding: 0px 20px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
}

.course-text-header {
  padding: 0px 24px;
  font-size: 18px;
  margin-top: 12px;

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 9px;
  }
}

.course-icons {
  @media screen and (max-width: 599px) {
    height: 18px;
    width: 18px;
  }
}

.course-text {
  color: #202020;
  @media screen and (min-width: 1601px) {
    font-size: 14px;
    padding-left: 30px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 14px;
    padding-left: 15px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
    padding-left: 10px;
  }

  @media screen and (max-width: 599px) {
    font-size: 8px;
    padding-left: 5px;
  }
}

.tab-item-style {
  @media screen and (min-width: 1601px) {
    min-height: 200px;
    padding: 10px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    min-height: 200px;
    padding: 10px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    min-height: 320px;
    padding: 10px;
  }

  @media screen and (max-width: 599px) {
  }
}

.faker {
  color: transparent;
  float: right;
  @media screen and (min-width: 1601px) {
    height: 200px;
    width: 450px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    height: 200px;
    width: 350px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    height: 320px;
  }

  @media screen and (max-width: 599px) {
  }
}

.m-iframe {
  border-radius: 10px;
  @media screen and (min-width: 1601px) {
    width: 800px;
    height: 450px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    width: 640px;
    height: 360px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    width: 400px;
    height: 225px;
  }

  @media screen and (max-width: 599px) {
    width: 320px;
    height: 180px;
  }
}

.more-header {
  font-weight: 600;
  margin-left: 5%;
  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 12px;
  }
}
</style>
