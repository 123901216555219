<template>
  <div
    class="flip-card"
    style="
      border-style: none;
      border-radius: 20px;
      border-color: #cbe8ff;
      border-radius: 10px;
    "
    @click="courseSearchCardClicked()"
  >
    <div class="flip-card-inner" style="">
      <div class="flip-card-front">
        <v-img
          v-if="'image_url' in course"
          alt="course image"
          class="flip-card-inner-image"
          :src="course.image_url"
        ></v-img>
        <div
          v-else
          style="
            background: #666666;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
          "
          class="flip-card-inner-image"
        >
          <v-avatar :size="avatarSize" color="white">
            <span class="course-no-image">{{
              getCourseImageFromTitle(course.course_title)
            }}</span>
          </v-avatar>
        </div>
        <div
          style="
            border-radius: 0px 0px 10px 10px;
            height: 23%;
            padding-left: 4%;
            display: flex;
            align-items: center;
            text-align: left;
            overflow: hidden;
          "
        >
          {{ course.course_title }}
        </div>
      </div>
      <div class="flip-card-back" style="font-size: 12px">
        <div
          style="
            height: 77%;
            padding: 8%;
            display: flex;
            flex-direction: column;
            align-items: start;
          "
        >
          <div class="mt-3 d-flex justify-content-between" style="width: 100%">
            <div style="padding: 0px">
              <span style="color: #808080">Course Code:</span>
              <span>{{ course.course_key }}</span>
            </div>
          </div>
          <div class="mt-3" style="position: relative; bottom: 10px">
            <span style="color: #808080; padding-right: 2px">Offered By:</span>
            <span style="color: #0099dc">{{ course.organization_name }}</span>
          </div>
          <div class="mt-3" style="position: relative; bottom: 10px">
            This course offers {{ course.num_batches }} batch(s) now
          </div>
        </div>
        <v-card-title
          style="
            cursor: pointer;
            background-color: #0099dc;
            border-radius: 0px 0px 10px 10px;
            display: block;
            font-size: 16px;
            height: 23%;
            display: flex;
            align-content: center;
            justify-content: center;
          "
          class="white--text"
          >Details</v-card-title
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Logger } from "/global/utils/helpers";
import axios from "axios";

export default {
  name: "CourseSearchCard",
  props: {
    course: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    sizes() {
      if (this.small) {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "205px" : "210px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "358px" : "475px",
          bodyFontSize: ".75rem",
          imageWidth: "30%",
          titleFontSize: "1.125rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "12px" : "14px",
          lineClamp: 2,
        };
      } else {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "224px" : "320px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "493px" : "710px",
          bodyFontSize: ".875rem",
          imageWidth: this.$vuetify.breakpoint.lgAndDown ? "160px" : "230px",
          imageHeight: this.$vuetify.breakpoint.lgAndDown ? "220px" : "316px",
          titleFontSize: "1.5rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "14px" : "16px",
          lineClamp: 2,
          stat: {
            height: this.$vuetify.breakpoint.lgAndDown ? "60px" : "92px",
          },
        };
      }
    },
    avatarSize() {
      let s = this.screenSize;
      return s < 600 ? "80" : s < 900 ? "120" : s < 1601 ? "150" : "180";
    },
  },
  methods: {
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map(w => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    async courseSearchCardClicked() {
      const API_URL = process.env.VUE_APP_EDU_PUBLIC_API_URL;
      let requestId = "public_update_course_rank-" + Math.random(0, 999999999);
      await axios(API_URL, {
        method: "POST",
        data: {
          course_id: this.course.course_id,
          partner_code: this.course.partner_code,
          event_type: "view_course_details",
        },
        headers: {
          "Content-Type": "application/json",
          "Channel-Name": "public_update_course_rank",
          "Request-Id": requestId,
        },
      })
        .then(response => {
          this.searchedCourses = response.data.content.courses;
          this.renderNow = true;
        })
        .catch(error => {
          Logger.error(error);
        });
      await this.$router.push({
        path: `/course-details/${this.course.partner_code}/${this.course.course_id}`,
      });
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped lang="scss">
#courseNameImage {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #ffffff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  min-width: 100px;
  border: 1px solid #f1f1f1;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  @media screen and (min-width: 1601px) {
    width: 400px;
    height: 300px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    width: 320px;
    height: 240px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    width: 260px;
    height: 200px;
  }

  @media screen and (max-width: 599px) {
    width: 280px;
    height: 210px;
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  border: #cbe8ff solid 1px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-inner-image {
  border-radius: 10px 10px 0px 0px;
  @media screen and (min-width: 1601px) {
    width: 400px;
    height: 230px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    width: 320px;
    height: 185px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    width: 260px;
    height: 155px;
  }

  @media screen and (max-width: 599px) {
    width: 280px;
    height: 160px;
  }
}

.course-no-image {
  color: #666666;
  font-weight: 500;
  @media screen and (min-width: 1601px) {
    font-size: 65px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 50px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 40px;
  }

  @media screen and (max-width: 599px) {
    font-size: 32px;
  }
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
  background-color: white !important;
}

/* Style the back side */
.flip-card-back {
  background-color: #cbe8ff;
  transform: rotateY(180deg);
}
</style>
