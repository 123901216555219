<template>
  <v-card class="card-style">
    <v-row
      no-gutters
      style="padding-top: min(1%, 10px); padding-bottom: min(1%, 6px)"
    >
      <v-col cols="12" class="d-flex align-center">
        <img
          :src="require('./icons/B_N_Students.svg')"
          alt="Student"
          :height="iconSize"
          :width="iconSize"
        />
        <span
          style="font-weight: 600; padding-left: 4%; display: inline-block"
          class="header-text text-truncate"
          >{{ batch.batch_title }}</span
        ></v-col
      >
    </v-row>
    <v-row
      no-gutters
      class="d-flex align-center"
      style="padding-top: min(1%, 10px); padding-bottom: min(1%, 6px)"
    >
      <v-avatar :size="avatarSize" color="#0099dc" class="white--text">
        <span class="content-text">{{
          getImageFromTitle("teacher_name" in batch ? batch.teacher_name : "")
        }}</span>
      </v-avatar>
      <span
        v-if="'teacher_name' in batch"
        style="font-weight: 600; padding-left: 3%"
        class="content-text"
        >{{ batch.teacher_name }}</span
      >
    </v-row>
    <v-row no-gutters>
      <v-col :cols="screenSize < 600 ? '7' : '8'">
        <v-row
          no-gutters
          style="padding-top: min(1%, 10px); padding-bottom: min(1%, 6px)"
        >
          <v-col v-if="'days' in batch" cols="12" class="d-flex align-center">
            <img
              :src="require('./icons/B_D_Time.svg')"
              alt="Time"
              :height="0.75 * iconSize"
              :width="0.75 * iconSize"
              class="mr-3"
            />
            <div style="width: 100%" class="d-flex align-center">
              <div
                v-for="(day, index) in classTimes"
                :key="index"
                :class="['days-style', { outlined: hasBatchClass(day[0]) }]"
                @mouseenter="showClassTime(day[0])"
                @mouseleave="hideClassTime()"
              >
                {{ day[0].toUpperCase()[0] }}
              </div>
            </div>
          </v-col>
          <v-col v-else
            ><span style="color: white" class="content-text">empty</span></v-col
          >
        </v-row>
        <v-row no-gutters style="min-height: 31%; padding: 0px">
          <v-col cols="4" class="pa-0 d-flex justify-center align-center">
            <span class="time-text">{{ hover }}</span>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          style="padding-top: min(1%, 10px); padding-bottom: min(1%, 6px)"
          class="d-flex align-center"
        >
          <img
            :src="require('./icons/B_D_Date.svg')"
            alt="Date"
            :height="iconSize * 0.75"
            :width="iconSize * 0.75"
          />
          <span class="time-text" style="padding-left: 2%"
            >{{ batchBeginDate }} - {{ batchEndDate }}</span
          ></v-row
        >
      </v-col>
      <v-col :cols="screenSize < 600 ? '5' : '4'">
        <v-row
          no-gutters
          style="
            padding-top: min(1%, 10px);
            padding-bottom: min(1%, 6px);
            height: 34%;
          "
        >
          <span style="color: #0099fc" class="content-text">Type:</span
          ><span style="padding-left: 2%" class="content-text">{{
            batch.batch_type
          }}</span>
        </v-row>
        <v-row
          no-gutters
          class="d-flex align-center"
          style="
            padding-top: min(1%, 10px);
            padding-bottom: min(1%, 6px);
            height: 33%;
          "
        >
          <img
            src="./icons/Taka.svg"
            alt="$"
            :height="iconSize * 0.75"
            :width="iconSize * 0.75"
            style="
              filter: invert(53%) sepia(71%) saturate(3351%) hue-rotate(160deg)
                brightness(93%) contrast(101%);
            "
          /><span
            class="content-text"
            style="color: #0099dc; padding-left: 2%"
            >{{ batch.amount }}</span
          >
        </v-row>
        <v-row
          no-gutters
          class="d-flex align-center"
          style="
            padding-top: min(1%, 10px);
            padding-bottom: min(1%, 6px);
            height: 33%;
          "
        >
          <span
            v-if="'payment_type' in batch"
            class="content-text"
            style="color: #0099dc"
            >Payment Type:</span
          ><span v-if="'payment_type' in batch" style="margin-left: 2%">{{
            batch.payment_type
          }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="12"
        style="padding: 1%; padding-top: 3%"
        class="d-flex justify-center"
      >
        <v-btn
          id="public-course-batch-add-to-cart-button"
          :height="buttonDim.height"
          :width="buttonDim.width"
          min-height="25"
          color="#0099DC"
          class="white--text"
          :disabled="!addCondition || expireCondition"
          @click="batchEnrollClicked()"
        >
          <span class="content-text">{{ buttonText }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { months, suffixes, formatAMPM } from "/global/utils/helpers";
import moment from "moment";
export default {
  name: "BatchCard",
  props: {
    batch: {
      type: Object,
      default: () => {},
    },
    course: {
      type: Object,
      default: () => {},
    },
    subscribeButtonText: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      shouldShowClassTime: false,
      classTimes: new Map([
        ["saturday", "No Class"],
        ["sunday", "No Class"],
        ["monday", "No Class"],
        ["tuesday", "No Class"],
        ["wednesday", "No Class"],
        ["thursday", "No Class"],
        ["friday", "No Class"],
      ]),
      time: "",
      hover: "",
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    avatarSize() {
      let s = this.screenSize;
      return s < 600 ? "22" : s < 900 ? "28" : s < 1601 ? "36" : "42";
    },
    iconSize() {
      let s = this.screenSize;
      return s < 600 ? 20 : s < 900 ? 26 : s < 1601 ? 32 : 38;
    },
    buttonDim() {
      let s = this.screenSize;
      let height = s < 600 ? 25 : s < 900 ? 30 : s < 1601 ? 40 : 50;
      let width = s < 600 ? 100 : s < 900 ? 115 : s < 1601 ? 140 : 160;
      return { height: height, width: width };
    },
    addCondition() {
      return this.subscribeButtonText.toLowerCase() === "add to cart";
    },
    daysRemaining(){
      let today = new Date();
      let expDate = new Date(this.batch.end_date);
      let diffInDays = (expDate - today) / (1000 * 60 * 60 * 24);

      return diffInDays
    },
    expireCondition() {
      return this.daysRemaining <= 0;
    },
    buttonText() {
      if (this.expireCondition) return "Expired";
      else if(this.daysRemaining < 1) return "1 day left"
      else return this.subscribeButtonText;
    },
    doesSessionExist() {
      return this.$store.state.user.authToken !== undefined;
    },
    batchBeginDate() {
      let dateString = new Date(this.batch.begin_date).toUTCString().split(" ");
      return dateString[1] + " " + dateString[2] + " " + dateString[3];
    },
    batchEndDate() {
      let dateString = new Date(this.batch.end_date).toUTCString().split(" ");
      return dateString[1] + " " + dateString[2] + " " + dateString[3];
    },
  },
  methods: {
    async addToCart() {
      if (moment(this.batch.end_date).isBefore()) {
        this.$root.$emit("alert", [
          undefined,
          !this.$language ? "This batch is expired" : "এই ব্যাচের মেয়াদ শেষ",
        ]);
        return;
      }
      try {
        const item = {
          course_id: this.batch.course_id,
          partner_code: this.batch.partner_code,
          item_id: this.batch.batch_id,
          item_title: `${this.course.course_title} | ${this.batch.batch_title}`,
          item_body: {
            teacher: this.course.organization_name,
            begin_date: this.batch.begin_date,
            end_date: this.batch.end_date,
            num_students: this.batch.num_students,
          },
        };
        await this.$store.dispatch("cart/addItem", {
          item,
        });
        this.$root.$emit("alert", ["Success", "Successfully added to cart"]);
        this.$emit("added");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    formatDay(days) {
      let formattedArray = [];
      let i = 0;
      for (i = 0; i < days.length; i++) {
        let splitDay = days[i].split("#");
        let startTimeExtraction = parseInt(splitDay[1]);
        let endTimeExtraction = parseInt(splitDay[2]);
        let startTime = startTimeExtraction % 12;
        if (startTimeExtraction >= 12) {
          startTime += " PM";
        } else {
          startTime += " AM";
        }
        let endTime = endTimeExtraction % 12;
        if (endTime < 10)
          if (endTimeExtraction < 12) {
            endTime += " AM";
          } else {
            endTime += " PM";
          }
        formattedArray.push(
          splitDay[0] +
            ": " +
            this.returnTimeAMPM(splitDay[1]) +
            " - " +
            this.returnTimeAMPM(splitDay[2])
        );
      }
      return formattedArray;
    },
    returnTimeAMPM(time) {
      let splitTime = time.split(":");
      let hours = splitTime[0];
      let AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = splitTime[1];
      let finalTime = hours + ":" + minutes + " " + AmOrPm;
      return finalTime;
    },
    batchEnrollClicked() {
      this.addToCart();
    },
    getImageFromTitle(name) {
      let words = name.split(" ");
      return words
        .map((w) => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    hasBatchClass(day) {
      return !(day in this.batch.days);
    },

    showClassTime(day) {
      if (day in this.batch.days)
        this.hover = `${this.batch.days[day].start_time}-${this.batch.days[day].end_time}`;
      else this.hover = "   ";
    },

    hideClassTime() {
      this.hover = "";
    },

    makeClassTimeMap(days) {
      for (let i = 0; i < days.length; i++) {
        let day = days[i].split(" ")[0];
        let times = days[i].split(" ")[1];
        let start_time = times.split("-")[0];
        let end_time = times.split("-")[1];
        start_time = formatAMPM(
          start_time.split(":")[0],
          start_time.split(":")[1]
        );
        end_time = formatAMPM(end_time.split(":")[0], end_time.split(":")[1]);
        this.classTimes.set(day.toLowerCase(), start_time + " - " + end_time);
      }
    },

    formatDate(date) {
      date = new Date(date);
      return (
        suffixes[date.getDate()] +
        " " +
        months[date.getMonth()] +
        " " +
        date.getFullYear()
      );
    },
  },
};
</script>

<style scoped lang="scss">
.weekly-day-chip {
  border-radius: 5px;
  padding-left: 5px;
  height: 20px;
  width: 20px;
  font-size: 12px;
}

.card-style {
  border: 2px solid #0099dc;
  border-radius: 10px;
  @media screen and (min-width: 1601px) {
    width: 40%;
    padding: 18px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1600px) {
    width: 45%;
    padding: 2%;
  }

  @media screen and (min-width: 600px) and (max-width: 1024px) {
    width: 60%;
    padding: 2%;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    padding: 3%;
  }
}

.header-text {
  @media screen and (min-width: 1601px) {
    font-size: 20px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 18px;
  }

  @media screen and (max-width: 599px) {
    font-size: 14px;
  }
}

.content-text {
  @media screen and (min-width: 1601px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 14px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

.time-text {
  @media screen and (min-width: 1601px) {
    font-size: 14px;
  }

  @media screen and (min-width: 900px) and (max-width: 1600px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 11px;
  }

  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}

.chip-style {
  min-width: 16px !important;
  height: 22px !important;
  width: 22px !important;
  font-size: 12px;
  @media screen and (max-width: 599px) {
    height: 16px;
    width: 16px;
  }
}

.days-style {
  height: 22px;
  width: 22px;
  font-size: 15px;
  background-color: #0099dc;
  border-radius: 6px;
  color: white;
  text-align: center;
  margin-right: 1%;

  @media screen and (max-width: 599px) {
    height: 16px;
    width: 16px;
    font-size: 12px;
    border-radius: 3px;
  }
}

.outlined {
  border: 1px solid #0099dc;
  color: #0099dc;
  background-color: white;
}
</style>
