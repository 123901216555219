var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("new-course-details", {
    attrs: {
      partner_code: _vm.partner_code,
      course_id: _vm.course_id,
      is_enroll_trigger: _vm.is_enroll_trigger,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }