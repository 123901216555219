<template>
  <new-course-details
    :partner_code="partner_code"
    :course_id="course_id"
    :is_enroll_trigger="is_enroll_trigger"
  ></new-course-details>
</template>

<script>
import NewCourseDetails from "../../modules/course/components/NewCourseDetails.vue";
export default {
  name: "CourseDetails",
  components: { NewCourseDetails },
  props: {
    partner_code: {
      type: String,
      required: true,
    },
    course_id: {
      type: String,
      required: true,
    },
    is_enroll_trigger: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
