var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flip-card",
      staticStyle: {
        "border-style": "none",
        "border-radius": "10px",
        "border-color": "#cbe8ff",
      },
      on: {
        click: function ($event) {
          return _vm.courseSearchCardClicked()
        },
      },
    },
    [
      _c("div", { staticClass: "flip-card-inner" }, [
        _c(
          "div",
          { staticClass: "flip-card-front" },
          [
            "image_url" in _vm.course
              ? _c("v-img", {
                  staticClass: "flip-card-inner-image",
                  attrs: { alt: "course image", src: _vm.course.image_url },
                })
              : _c(
                  "div",
                  {
                    staticClass: "flip-card-inner-image",
                    staticStyle: {
                      background: "#666666",
                      display: "flex",
                      width: "100%",
                      "align-items": "center",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { size: _vm.avatarSize, color: "white" } },
                      [
                        _c("span", { staticClass: "course-no-image" }, [
                          _vm._v(
                            _vm._s(
                              _vm.getCourseImageFromTitle(
                                _vm.course.course_title
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
            _c(
              "div",
              {
                staticStyle: {
                  "border-radius": "0px 0px 10px 10px",
                  height: "23%",
                  "padding-left": "4%",
                  display: "flex",
                  "align-items": "center",
                  "text-align": "left",
                  overflow: "hidden",
                },
              },
              [_vm._v(" " + _vm._s(_vm.course.course_title) + " ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "flip-card-back",
            staticStyle: { "font-size": "12px" },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  height: "77%",
                  padding: "8%",
                  display: "flex",
                  "flex-direction": "column",
                  "align-items": "start",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "mt-3 d-flex justify-content-between",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", { staticStyle: { padding: "0px" } }, [
                      _c("span", { staticStyle: { color: "#808080" } }, [
                        _vm._v("Course Code:"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.course.course_key))]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-3",
                    staticStyle: { position: "relative", bottom: "10px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#808080",
                          "padding-right": "2px",
                        },
                      },
                      [_vm._v("Offered By:")]
                    ),
                    _c("span", { staticStyle: { color: "#0099dc" } }, [
                      _vm._v(_vm._s(_vm.course.organization_name)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-3",
                    staticStyle: { position: "relative", bottom: "10px" },
                  },
                  [
                    _vm._v(
                      " This course offers " +
                        _vm._s(_vm.course.num_batches) +
                        " batch(s) now "
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "v-card-title",
              {
                staticClass: "white--text",
                staticStyle: {
                  cursor: "pointer",
                  "background-color": "#0099dc",
                  "border-radius": "0px 0px 10px 10px",
                  display: "flex",
                  "font-size": "16px",
                  height: "23%",
                  "align-content": "center",
                  "justify-content": "center",
                },
              },
              [_vm._v("Details")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }