var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-style" },
    [
      _c(
        "v-row",
        {
          staticStyle: {
            "padding-top": "min(1%, 10px)",
            "padding-bottom": "min(1%, 6px)",
          },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "12" } },
            [
              _c("img", {
                attrs: {
                  src: require("./icons/B_N_Students.svg"),
                  alt: "Student",
                  height: _vm.iconSize,
                  width: _vm.iconSize,
                },
              }),
              _c(
                "span",
                {
                  staticClass: "header-text text-truncate",
                  staticStyle: {
                    "font-weight": "600",
                    "padding-left": "4%",
                    display: "inline-block",
                  },
                },
                [_vm._v(_vm._s(_vm.batch.batch_title))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "d-flex align-center",
          staticStyle: {
            "padding-top": "min(1%, 10px)",
            "padding-bottom": "min(1%, 6px)",
          },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-avatar",
            {
              staticClass: "white--text",
              attrs: { size: _vm.avatarSize, color: "#0099dc" },
            },
            [
              _c("span", { staticClass: "content-text" }, [
                _vm._v(
                  _vm._s(
                    _vm.getImageFromTitle(
                      "teacher_name" in _vm.batch ? _vm.batch.teacher_name : ""
                    )
                  )
                ),
              ]),
            ]
          ),
          "teacher_name" in _vm.batch
            ? _c(
                "span",
                {
                  staticClass: "content-text",
                  staticStyle: { "font-weight": "600", "padding-left": "3%" },
                },
                [_vm._v(_vm._s(_vm.batch.teacher_name))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.screenSize < 600 ? "7" : "8" } },
            [
              _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-top": "min(1%, 10px)",
                    "padding-bottom": "min(1%, 6px)",
                  },
                  attrs: { "no-gutters": "" },
                },
                [
                  "days" in _vm.batch
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("img", {
                            staticClass: "mr-3",
                            attrs: {
                              src: require("./icons/B_D_Time.svg"),
                              alt: "Time",
                              height: 0.75 * _vm.iconSize,
                              width: 0.75 * _vm.iconSize,
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-center",
                              staticStyle: { width: "100%" },
                            },
                            _vm._l(_vm.classTimes, function (day, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class: [
                                    "days-style",
                                    { outlined: _vm.hasBatchClass(day[0]) },
                                  ],
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.showClassTime(day[0])
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.hideClassTime()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(day[0].toUpperCase()[0]) + " "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _c("v-col", [
                        _c(
                          "span",
                          {
                            staticClass: "content-text",
                            staticStyle: { color: "white" },
                          },
                          [_vm._v("empty")]
                        ),
                      ]),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticStyle: { "min-height": "31%", padding: "0px" },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 d-flex justify-center align-center",
                      attrs: { cols: "4" },
                    },
                    [
                      _c("span", { staticClass: "time-text" }, [
                        _vm._v(_vm._s(_vm.hover)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: {
                    "padding-top": "min(1%, 10px)",
                    "padding-bottom": "min(1%, 6px)",
                  },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("./icons/B_D_Date.svg"),
                      alt: "Date",
                      height: _vm.iconSize * 0.75,
                      width: _vm.iconSize * 0.75,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "time-text",
                      staticStyle: { "padding-left": "2%" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.batchBeginDate) +
                          " - " +
                          _vm._s(_vm.batchEndDate)
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.screenSize < 600 ? "5" : "4" } },
            [
              _c(
                "v-row",
                {
                  staticStyle: {
                    "padding-top": "min(1%, 10px)",
                    "padding-bottom": "min(1%, 6px)",
                    height: "34%",
                  },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "content-text",
                      staticStyle: { color: "#0099fc" },
                    },
                    [_vm._v("Type:")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "content-text",
                      staticStyle: { "padding-left": "2%" },
                    },
                    [_vm._v(_vm._s(_vm.batch.batch_type))]
                  ),
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: {
                    "padding-top": "min(1%, 10px)",
                    "padding-bottom": "min(1%, 6px)",
                    height: "33%",
                  },
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("img", {
                    staticStyle: {
                      filter:
                        "invert(53%) sepia(71%) saturate(3351%) hue-rotate(160deg)",
                    },
                    attrs: {
                      src: require("./icons/Taka.svg"),
                      alt: "$",
                      height: _vm.iconSize * 0.75,
                      width: _vm.iconSize * 0.75,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "content-text",
                      staticStyle: { color: "#0099dc", "padding-left": "2%" },
                    },
                    [_vm._v(_vm._s(_vm.batch.amount))]
                  ),
                ]
              ),
              _c(
                "v-row",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: {
                    "padding-top": "min(1%, 10px)",
                    "padding-bottom": "min(1%, 6px)",
                    height: "33%",
                  },
                  attrs: { "no-gutters": "" },
                },
                [
                  "payment_type" in _vm.batch
                    ? _c(
                        "span",
                        {
                          staticClass: "content-text",
                          staticStyle: { color: "#0099dc" },
                        },
                        [_vm._v("Payment Type:")]
                      )
                    : _vm._e(),
                  "payment_type" in _vm.batch
                    ? _c("span", { staticStyle: { "margin-left": "2%" } }, [
                        _vm._v(_vm._s(_vm.batch.payment_type)),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center",
              staticStyle: { padding: "1%", "padding-top": "3%" },
              attrs: { cols: "12" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    id: "public-course-batch-add-to-cart-button",
                    height: _vm.buttonDim.height,
                    width: _vm.buttonDim.width,
                    "min-height": "25",
                    color: "#0099DC",
                    disabled: !_vm.addCondition || _vm.expireCondition,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchEnrollClicked()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "content-text" }, [
                    _vm._v(_vm._s(_vm.buttonText)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }