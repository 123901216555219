var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-main",
        { staticStyle: { height: "100%", padding: "0px" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "450px" },
              model: {
                value: _vm.showContactForm,
                callback: function ($$v) {
                  _vm.showContactForm = $$v
                },
                expression: "showContactForm",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: { "border-radius": "10px !important" },
                  attrs: { rounded: "" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "justify-content-center",
                      staticStyle: { "white-space": "nowrap" },
                    },
                    [_vm._v("Inquire About This Course")]
                  ),
                  _c("submission-alert-message", {
                    ref: "alertMessage",
                    attrs: { message: _vm.badSubmissionMessage[_vm.$language] },
                    model: {
                      value: _vm.showGeneralMessage,
                      callback: function ($$v) {
                        _vm.showGeneralMessage = $$v
                      },
                      expression: "showGeneralMessage",
                    },
                  }),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticStyle: {
                        "z-index": "6",
                        "max-width": "100%",
                        padding: "5%",
                      },
                      model: {
                        value: _vm.formValidated,
                        callback: function ($$v) {
                          _vm.formValidated = $$v
                        },
                        expression: "formValidated",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-text-field", {
                            staticStyle: { "max-height": "25px" },
                            attrs: {
                              dense: "",
                              flat: "",
                              outlined: "",
                              label: "Your Name",
                              rules: _vm.nameRules,
                              "validate-on-blur": "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "mt-8",
                            staticStyle: { "max-height": "25px" },
                            attrs: {
                              prefix: _vm.showPhonePrefix ? "+880" : "",
                              dense: "",
                              outlined: "",
                              label: "Phone Number",
                              rules: [_vm.required, _vm.phoneValidation],
                              "validate-on-blur": "",
                            },
                            on: {
                              focus: function ($event) {
                                _vm.showPhonePrefix = true
                              },
                              blur: function ($event) {
                                return _vm.hidePhonePrefix()
                              },
                            },
                            model: {
                              value: _vm.phone,
                              callback: function ($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "mt-8",
                            staticStyle: { "max-height": "25px" },
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: "Email Address",
                              rules: [_vm.emailValidation],
                              "validate-on-blur": "",
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c("v-textarea", {
                            staticClass: "mt-8",
                            attrs: {
                              "auto-grow": "",
                              outlined: "",
                              label: "Type your message",
                            },
                            model: {
                              value: _vm.message,
                              callback: function ($$v) {
                                _vm.message = $$v
                              },
                              expression: "message",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "submit-btn white--text",
                              attrs: {
                                elevation: "0",
                                width: "50%",
                                color: "#0099DC",
                                height: "42",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.submitContactForm()
                                },
                              },
                            },
                            [_vm._v("Send")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "basic-details-style",
              attrs: { color: "#EBF2FF", flat: "" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-column justify-center basic-details-first-row",
                      attrs: { cols: _vm.screenSize < 900 ? "12" : "8" },
                    },
                    [
                      _c("span", { staticClass: "details-header" }, [
                        _vm._v(" " + _vm._s(_vm.course.course_title) + " "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "details-course-code",
                          staticStyle: { "font-weight": "400" },
                        },
                        [
                          _c("span", { staticClass: "details-header" }, [
                            _vm._v("("),
                          ]),
                          _vm._v(
                            " Course-Code: " +
                              _vm._s(_vm.course.course_key) +
                              " "
                          ),
                          _c("span", { staticClass: "details-header" }, [
                            _vm._v(")"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "d-flex basic-details-later-rows" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "details-sub-header",
                      attrs: { cols: _vm.screenSize < 600 ? "12" : "8" },
                    },
                    [
                      _c("v-row", [
                        _c(
                          "span",
                          {
                            staticClass: "details-course-code",
                            staticStyle: { "font-weight": "600" },
                          },
                          [_vm._v("Course Instructor:")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "details-course-code",
                            staticStyle: {
                              "font-weight": "500",
                              "padding-left": "1%",
                            },
                          },
                          [_vm._v(_vm._s(Object.values(_vm.instructors)[0]))]
                        ),
                        _vm.numInstrcutors > 1
                          ? _c(
                              "span",
                              {
                                staticClass: "details-course-code",
                                staticStyle: {
                                  "font-weight": "500",
                                  "padding-left": "1%",
                                },
                              },
                              [
                                _vm._v(
                                  "+" +
                                    _vm._s(_vm.numInstrcutors) +
                                    " more instructors"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "v-row",
                        { class: _vm.screenSize < 600 ? "mt-1" : "mt-3" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "details-course-code",
                              staticStyle: { "font-weight": "600" },
                            },
                            [_vm._v("Offered By:")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "details-course-code",
                              staticStyle: {
                                "font-weight": "500",
                                "padding-left": "1%",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.course.organization_name))]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "d-flex course-button-section" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text course-button-class",
                              attrs: {
                                id: "public-course-details-enroll-now-button",
                                depressed: "",
                                color: "#0099DC",
                                disabled: !(
                                  _vm.subscribeButtonText.toLowerCase() ===
                                  "add to cart"
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.enrollNowClicked()
                                },
                              },
                            },
                            [_c("span", [_vm._v("Enroll Now")])]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "white--text course-button-class contact-btn",
                              attrs: {
                                id: "public-course-details-contact-us-button",
                                depressed: "",
                                color: "#0099DC",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showContactForm = !_vm.showContactForm
                                },
                              },
                            },
                            [_c("span", [_vm._v("Contact")])]
                          ),
                        ],
                        1
                      ),
                      _vm.course.facebook_group
                        ? _c("v-row", { staticClass: "d-flex align-center" }, [
                            _c(
                              "a",
                              {
                                class: [
                                  _vm.screenSize >= 900 ? "mt-6" : "mt-3",
                                  "details-course-code",
                                ],
                                attrs: {
                                  id: "public-course-details-facebook-button",
                                  href: _vm.course.facebook_group,
                                  target: "_blank",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#0099dc" } },
                                  [
                                    _vm._v(
                                      "Join our Facebook Group to stay connected"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { cols: _vm.screenSize < 600 ? "12" : "4" },
                    },
                    [
                      _vm.courseMaterialExists
                        ? _c(
                            "v-card",
                            {
                              staticClass: "course-inclusion-card-style",
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        cols: _vm.screenSize < 600 ? "4" : "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "inner-card",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          "image_url" in _vm.course
                                            ? _c("v-img", {
                                                staticClass: "course-image",
                                                attrs: {
                                                  alt: "course image",
                                                  src: _vm.courseImage,
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    background: "#666666",
                                                    display: "flex",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      attrs: {
                                                        size: _vm.avatarSize,
                                                        color: "white",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "course-no-image",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getCourseImageFromTitle(
                                                                _vm.course
                                                                  .course_title
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        cols: _vm.screenSize < 600 ? "8" : "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "course-text-header" },
                                        [_vm._v("This Course Includes")]
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          class:
                                            _vm.screenSize < 600
                                              ? ""
                                              : "pt-5 pa-0",
                                        },
                                        _vm._l(
                                          _vm.svglocs,
                                          function (item, index) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: index,
                                                class: [
                                                  "d-flex justify start align-center course-text-holder",
                                                  {
                                                    "pa-0 ma-0":
                                                      Number(
                                                        _vm.course
                                                          .course_materials[
                                                          item.iname
                                                        ]
                                                      ) == 0,
                                                  },
                                                ],
                                                attrs: {
                                                  cols:
                                                    _vm.screenSize < 600
                                                      ? "6"
                                                      : "12",
                                                },
                                              },
                                              [
                                                Number(
                                                  _vm.course.course_materials[
                                                    item.iname
                                                  ]
                                                ) > 0
                                                  ? _c("div", [
                                                      _c("img", {
                                                        staticClass:
                                                          "course-icons",
                                                        attrs: {
                                                          src: item.svg,
                                                          alt: "Item",
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "course-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.course
                                                                .course_materials[
                                                                item.iname
                                                              ]
                                                            ) +
                                                              " " +
                                                              _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.course
                                                        .course_materials[
                                                        item.iname
                                                      ] > 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "course-text",
                                                              staticStyle: {
                                                                padding: "0px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.mult
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "2% 5%" } },
            [
              _c(
                "v-card",
                { staticStyle: { "padding-top": "1%" }, attrs: { flat: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { id: "tabs", "show-arrows": false },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-class",
                          attrs: { id: "public-course-details-about-tab" },
                        },
                        [_vm._v("About")]
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-class",
                          attrs: { id: "public-course-details-outline-tab" },
                        },
                        [_vm._v("Outline")]
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-class",
                          attrs: {
                            id: "public-course-details-instructors-tab",
                          },
                        },
                        [_vm._v("Instructor(s)")]
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "tab-class",
                          attrs: { id: "public-course-details-enrollmet-tab" },
                        },
                        [_vm._v("Enrollment Options")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      staticStyle: { "margin-bottom": "6%" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c("v-tab-item", { staticClass: "tab-item-style" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mt-6",
                            staticStyle: {
                              "font-size": "20px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" About This Course ")]
                        ),
                        _c(
                          "div",
                          [
                            _vm.course.about_video
                              ? _c(
                                  "v-row",
                                  { attrs: { "no-gutter": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "px-0 pt-5 pb-10 d-flex justify-center",
                                        attrs: {
                                          cols:
                                            _vm.screenSize < 600 ? "12" : "8",
                                        },
                                      },
                                      [
                                        _c("iframe", {
                                          staticClass: "m-iframe",
                                          attrs: {
                                            src: _vm.processAboutVid,
                                            allow:
                                              "accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture",
                                            allowfullscreen: "",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.course.about_video
                              ? _c("div", {
                                  staticClass: "with-inner-html",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.course.course_description
                                    ),
                                  },
                                })
                              : _c("div", { staticClass: "faker" }),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { staticClass: "tab-item-style" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mt-6",
                            staticStyle: {
                              "font-size": "20px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Outline for this course ")]
                        ),
                        _c("div", {
                          staticClass: "mt-6 with-inner-html",
                          staticStyle: { "font-size": "14px" },
                          domProps: { innerHTML: _vm._s(_vm.course.syllabus) },
                        }),
                      ]),
                      _c(
                        "v-tab-item",
                        { staticClass: "tab-item-style" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-6",
                              staticStyle: {
                                "font-size": "20px",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(" Instructors ")]
                          ),
                          _c(
                            "v-layout",
                            {
                              staticStyle: { width: "86%", "margin-top": "2%" },
                              attrs: { wrap: "" },
                            },
                            [
                              _vm._l(_vm.instructors, function (instructor, i) {
                                return _c(
                                  "v-flex",
                                  {
                                    key: i,
                                    staticClass: "mb-8",
                                    attrs: { sm6: "", md6: "", lg6: "" },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "pl-4",
                                        staticStyle: { width: "100vw" },
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            attrs: {
                                              color: "#445599",
                                              size:
                                                _vm.$vuetify.breakpoint.width <
                                                1360
                                                  ? 70
                                                  : 100,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text headline",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getImageFromTitle(
                                                      instructor
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "ml-6" },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  "font-size": "20px",
                                                },
                                              },
                                              [_vm._v(_vm._s(instructor))]
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  color: "#707070",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.course.organization_name
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-row",
                                              {
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  color: "#0089cb",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.scrollTo(
                                                      "more-courses"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "View more courses by this organization"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _vm.instructors.length < 1
                                ? _c("v-flex", [
                                    _vm._v(
                                      "The are no instructors in this course."
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mt-6",
                              staticStyle: {
                                "font-size": "20px",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _vm._v(" Offered By : "),
                              _c(
                                "v-row",
                                { staticClass: "pl-3 mt-4" },
                                [
                                  _c(
                                    "v-avatar",
                                    { attrs: { color: "#445599" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "white--text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getImageFromTitle(
                                                _vm.course.organization_name
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "mt-2 ml-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.course.organization_name)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-tab-item", { staticClass: "tab-item-style" }, [
                        _c(
                          "div",
                          { staticClass: "mt-6 pl-0 pt-6" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v("Batches")]
                            ),
                            _vm.renderBatchesNow === false
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center",
                                    staticStyle: { "padding-top": "6%" },
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "#0099dc",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.renderBatchesNow === true
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.batchesForCourse,
                                    function (batch) {
                                      return _c("batch-card", {
                                        key: batch.batch_id,
                                        staticClass: "mt-6",
                                        attrs: {
                                          batch: batch,
                                          course: _vm.course,
                                          "subscribe-button-text":
                                            _vm.subscribeButtonText,
                                        },
                                        on: {
                                          "update:subscribeButtonText":
                                            function ($event) {
                                              _vm.subscribeButtonText = $event
                                            },
                                          "update:subscribe-button-text":
                                            function ($event) {
                                              _vm.subscribeButtonText = $event
                                            },
                                          added: function ($event) {
                                            return _vm.getCartItems()
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm.batchesForCourse.length < 1
                              ? _c("v-flex", { staticClass: "mt-6" }, [
                                  _vm._v("The are no batches in this course."),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.moreCoursesByOrg.length > 1
                ? _c(
                    "div",
                    { staticStyle: { "margin-bottom": "2%" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            width: "90%",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "more-header",
                              attrs: { id: "more-courses" },
                            },
                            [_vm._v(" More Courses by this Organization ")]
                          ),
                          _vm.moreCoursesByOrg.length > 4 &&
                          _vm.exploringMore === false
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "font-weight": "bold",
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.exploringMore = true
                                    },
                                  },
                                },
                                [_vm._v(" Explore More ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.exploringMore === true
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                height: "320px",
                                width: "90%",
                                "margin-top": "1.4%",
                                "overflow-y": "hidden",
                                "white-space": "nowrap",
                              },
                            },
                            _vm._l(_vm.moreCoursesByOrg, function (c, i) {
                              return _c("course-search-card", {
                                key: i,
                                staticClass: "mr-6",
                                staticStyle: { display: "inline-block" },
                                attrs: { course: c },
                                on: {
                                  clicked: function ($event) {
                                    _vm.fetchCourseDetails()
                                    _vm.fetchBatchesForCourse()
                                  },
                                },
                              })
                            }),
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                height: "320px",
                                "max-width": "90%",
                                "margin-top": "1.4%",
                                "overflow-y": "scroll",
                                "white-space": "nowrap",
                              },
                            },
                            _vm._l(
                              _vm.moreCoursesByOrg.slice(0, 6),
                              function (c, i) {
                                return _c("course-search-card", {
                                  key: i,
                                  staticClass: "mr-6",
                                  staticStyle: { display: "inline-block" },
                                  attrs: { course: c },
                                  on: {
                                    clicked: function ($event) {
                                      _vm.fetchCourseDetails()
                                      _vm.fetchBatchesForCourse()
                                    },
                                  },
                                })
                              }
                            ),
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }